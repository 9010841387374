import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SlideService } from 'src/app/services/slide.service';
import { Toaster } from 'ngx-toast-notifications';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-slideList',
  templateUrl: './slideList.component.html',
  styleUrls: ['./slideList.component.scss']
})
export class SlideListComponent implements OnInit {
  imageForm: FormGroup;
  slideImages = [];
  selectedImage: any = null;
  isModalOpen = false;
  modalTitle = 'Agregar Imagen';
  defaultImageUrl = 'assets/img/notImage.png';
  filteredSlides = [];
  currentPage = 1;
  itemsPerPage = 10;
  totalPages = 1;
  totalSlides: number = 0;
  preloader = false;

  constructor(private fb: FormBuilder, private slideService: SlideService, private toaster: Toaster) {
    this.imageForm = this.fb.group({
      code: ['', Validators.required],
      title: ['', [Validators.required, Validators.maxLength(20)]],
      searchTerm: ['']
    });
  }

  ngOnInit(): void {
    this.filterSlides();
  }

  filterSlides(): void {
    this.currentPage = 1;
    this.loadSlideImage();
  }

  loadSlideImage(): void {
    this.preloader = true;
    const { searchTerm } = this.imageForm.value;
    const page = this.currentPage;
    const limit = this.itemsPerPage;
    const term = {
      searchTerm: searchTerm || ''
    };

    this.slideService.getSlides(term, page, limit).pipe(
      catchError(error => {
        this.toaster.open({ text: 'Error al obtener las imágenes. Por favor, intente nuevamente.', type: 'danger' });
        return of({ data: [], totalSlides: 0 });
      })
    ).subscribe(response => {
      this.filteredSlides = response.slides;
      this.totalSlides = response.totalSlides;
      this.totalPages = Math.ceil(this.totalSlides / this.itemsPerPage);
      this.preloader = false;
    });
  }

  onImageError(event: Event) {
    (event.target as HTMLImageElement).src = this.defaultImageUrl;
  }

  changePage(page: number): void {
    this.currentPage = page;
    this.loadSlideImage();
  }

  openModal(action: string, image: any = null): void {
    this.isModalOpen = true;
    this.modalTitle = action === 'add' ? 'Agregar Imagen' : 'Editar Imagen';
    if (action === 'edit' && image) {
      this.selectedImage = image;
      this.imageForm.setValue({
        code: image.code,
        title: image.title.toUpperCase(),
        searchTerm:''
      });
    } else {
      this.imageForm.reset();
      this.selectedImage = null;
    }
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  saveImage(): void {
    const { code, title } = this.imageForm.value;
    const titleUpper = title.toUpperCase();
    if (this.selectedImage) {
      this.selectedImage.code = code;
      this.selectedImage.title = titleUpper;
      this.slideService.update(this.selectedImage).pipe(
        catchError(error => {
          this.toaster.open({ text: 'Error al actualizar la imagen. Por favor, intente nuevamente.', type: 'danger' });
          return of(null);
        })
      ).subscribe(response => {
        if (response) {
          this.toaster.open({ text: 'Imagen actualizada satisfactoriamente.', type: 'success' });
          this.loadSlideImage();
        }
      });
    } else {
      this.slideService.add({ code, title:titleUpper }).pipe(
        catchError(error => {
          this.toaster.open({ text: 'Error al agregar la imagen. Por favor, intente nuevamente.', type: 'danger' });
          return of(null);
        })
      ).subscribe(response => {
        if (response) {
          this.toaster.open({ text: 'Imagen agregada satisfactoriamente.', type: 'success' });
          this.loadSlideImage();
        }
      });
    }

    this.closeModal();
  }

  deleteImage(image: any): void {
    if (confirm('¿Estás seguro de que quieres eliminar esta imagen?')) {
      this.slideService.delete(image._id).subscribe(
        () => {
          this.toaster.open({ text: 'Imagen eliminada satisfactoriamente.', type: 'success' });
          this.filterSlides(); 
        },
        error => {
          this.toaster.open({ text: 'Se produjo un error al eliminar la imagen.', type: 'danger' });
          console.error('Error al eliminar:', error);
        }
      );
    }
  }

  
  getDisplayedRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(this.currentPage * this.itemsPerPage, this.totalSlides);
    return `${start}-${end}`;
  }
}
