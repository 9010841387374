import { Component, OnInit, Input } from '@angular/core';
import { Product } from '../../models/product'
import { HistorialService } from 'src/app/services/historial.service';
@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  //@Input()
  listaHistorialConsultas: String[];
  //@Input()
  listaHistorialProducts: Product[];

  constructor(private historialService: HistorialService) { }

  ngOnInit() {
    this.listaHistorialConsultas = this.historialService.getConsultas();
    this.listaHistorialProducts = this.historialService.getProductos();

  }

  
}
