import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SlideService {
  URL_API = "https://www.partes.do/api/ises/slide";
  //URL_API = "http://localhost:3000/api/ises/slide";

  constructor(private httpBackend: HttpClient) { }

  add(slide: any): Observable<any> {
    return this.httpBackend.post<any>(`${this.URL_API}`, slide, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(
      catchError(this.handleError('add', {}))
    );
  }

  update(slide: any): Observable<any> {
    return this.httpBackend.put<any>(`${this.URL_API}/${slide._id}`, slide, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(
      catchError(this.handleError('update', {}))
    );
  }

  delete(id: string): Observable<any> {
    return this.httpBackend.delete<any>(`${this.URL_API}/${id}`)
      .pipe(
        catchError(this.handleError('delete', {}))
      );
  }

  getSlides(term: any, page: number, limit: number): Observable<any> {
    const body = {
      searchTerm: term.searchTerm,
      page: page,
      limit: limit
    };
    return this.httpBackend.post<any>(`${this.URL_API}/list`, body, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }).pipe(
      catchError(this.handleError('getSlides', { data: [], totalSlides: 0 }))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
