import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private selectedProduct: any = null;

  setSelectedProduct(imageCode: any, product: any) {
    this.selectedProduct = {
      imageCode: imageCode,
      product: product
    };
  }

  getSelectedProduct() {
    return this.selectedProduct;
  }

  clearSelectedProduct() {
    this.selectedProduct = null;
  }
}
