import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastNotificationsModule } from "ngx-toast-notifications";

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule }    from '@angular/common/http';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartComponent } from './components/cart/cart.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProductdetailComponent } from './components/productdetail/productdetail.component';
import { ProductcartComponent } from './components/productcart/productcart.component';
import { QuoteformComponent } from './components/quoteform/quoteform.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { HistoryComponent } from './components/history/history.component';
import { AboutComponent } from './components/about/about.component';
import { BillingComponent } from './components/billing/billing.component';
import { SlideshomeComponent } from './components/slideshome/slideshome.component';
import { LoginComponent } from './components/login/login.component';
import { QuoteListComponent } from './components/quoteList/quoteList.component';
import { CartService } from './services/cart.service';
import { HeaderSeoService } from './services/header-seo.service';
import { DatacollectorComponent } from './components/datacollector/datacollector.component';
import { SlideListComponent } from './components/slideList/slideList.component';
import { RouterModule } from '@angular/router';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { LoginService } from './services/login.service';
import { AuthGuard } from './auth.guard';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    ContactComponent,
    ProductdetailComponent,
    ProductcartComponent,
    QuoteformComponent,
    TerminosComponent,
    ScrollTopComponent,
    HistoryComponent,
    AboutComponent,
    BillingComponent,
    SlideshomeComponent,
    DatacollectorComponent,
    LoginComponent,
    QuoteListComponent,
    SlideListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastNotificationsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    RouterModule,
    LazyLoadImagesModule,
  ],
  providers: [
    CartService,
    HeaderSeoService,
    LoginService,
    AuthGuard,
    AuthService,
    SharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
