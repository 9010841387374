import { Component, OnInit } from '@angular/core';


declare var jsPDF: any;
@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.scss']
})
export class ProductdetailComponent implements OnInit {


  datosin = {
    quote: "000001530"
  }

  profile = {
    clientName: "Ing. Miguel Casanova",
    clientEmail: "tyler.ve@gmail.com",
    rncNumber: "1234567",
    isFreezone: "Si"
  }

  cart = [{
    product: {
      _id: 'qwerty',
      code: '000001004163',
      price: 9358.17,
      stock: 7,
      image: 'http://americana.com.do/media/catalog/product/cache/1/image/1000x1330/9df78eab33525d08d6e5fb8d27136e95/0/0/000001004163.jpg',
      description: 'VALV. P/FILTRO LATERAL 2 PENTAIR 261055',
      brand: 'PENTAIR POOL',
      garanty: 'GARANTIA DEL PROVEEDOR /O NO TIENE'
    },
    quantity:3
  },
  {
    product: {
      _id: 'debora',
      code: '000001004164',
      price: 2458.17,
      stock: 9,
      image: 'http://isesindustrial.com/pict/ises.jpg',
      description: 'VALV. P/FILTRO LATERAL 2 PENTAIR 261055',
      brand: 'PENTAIR POOL',
      garanty: 'GARANTIA DEL PROVEEDOR /O NO TIENE'
    },
    quantity:3
  },
  {
    product: {
      _id: 'angular',
      code: '000001004165',
      price: 3558.87,
      stock: 9,
      image: 'http://isesindustrial.com/pict/ises.jpg',
      description: 'VALV. P/FILTRO LATERAL 2 PENTAIR 261055',
      brand: 'PENTAIR POOL',
      garanty: 'GARANTIA DEL PROVEEDOR /O NO TIENE'
    },
    quantity:3
  }];

  date = new Date()

  constructor() { }

  ngOnInit() {
  }
  submitValues(){

  }

  downloadPDF(){
    let doc = new jsPDF({ format: "letter"})
    var datos : Array<Object> = [];
    var datosin = {
      code: "**",
      custumer: "Miguel Angel Suarez",
      dateQuote: "2019-02-12T15:53:30.723Z",
      email: "tyler.ve@gmail.com",
      itbis: "4989.37",
      products: [],
      quoteNumber: 1518,
      rncCustumer: "1234567",
      subTotal: "27718.74",
      total: "32708.11"
    }
    if(this.cart.length){
      var i = 1;
      for (let entry of this.cart) {
        let quantity = entry['quantity'];
        let price = entry['product']['price'];
        let total = quantity * price;
        datos.push([
          i++,
          entry['quantity'],
          entry['product']['code'],
          entry['product']['description'],
          entry['product']['price'].toFixed(2),
          total.toFixed(2),
        ]);
        datos.push([
          '',
          '',
          '',
          'lead time: 1 day',
          '',
          ''
        ]);

      }
    }

    var imgLogo = new Image();
    imgLogo.src = 'assets/img/logo.png';
    doc.addImage(imgLogo, 'png', 15, 15, 65, 30);
    doc.setFillColor("#1565C0");

    doc.setTextColor("#000");
    doc.setFontSize(10);

    doc.text('Contacto: ' ,15 , 50);
    doc.text("(809)957-0676" ,36 , 50);
    doc.text('Email: ' ,15 , 55);
    doc.text("ventas@isesindustrial.com / ises1114@hotmail.com" ,36 , 55);
    doc.text('RNC: ' ,15 , 60);
    doc.text("1-31-48955-9" ,36 , 60);
    doc.text('Dirección: ' ,15 , 65);
    doc.text('Km. 18 de los bajos de Haina' ,36 , 65);

    let fechaHoy = new  Date();
    doc.text('Cotización #: ',128, 20);
    doc.text(this.datosin["quote"] ,160, 20);

    doc.text('Solicitado por: ',128, 35);
    doc.text(this.profile.clientName,160, 35);
    doc.text('Fecha: ' ,128, 40);
    doc.text( fechaHoy.toLocaleString('es-ES').split(' ')[0] ,160, 40);

    //doc.text('Zona Franca: ' + this.profile.isFreezone ,135, 45);
    doc.text('Email: ',128, 45);
    doc.text(this.profile.clientEmail,160, 45);
    doc.text('RNC: ',128, 50);
    doc.text(this.profile.rncNumber,160, 50);
    doc.text('Zona franca: ',128, 55);
    if(this.profile.isFreezone){
      doc.text('Si' ,160, 55);
    }else{
      doc.text('No' ,160, 55);
    }


    doc.setFontSize(16);
    doc.text('COTIZACIÓN ELECTRÓNICA' ,80 , 75);

    doc.autoTable({
      head: [['Item','Cant.', 'Código', 'Descripción', 'P/U $', 'SubTotal $']],
      body: datos,
      startY: 80

    });
    let finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(8);
    doc.text('NOTA 1: Cotización sujeta a términos.', 15, 255);
    //doc.setTextColor("#DD0000");
    doc.text('NOTA 2: Cotización en US Dollar.', 15, 260);


    doc.setTextColor("#000");
    finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12);
    doc.setFillColor("#000");
    doc.line(10,248,205 ,248);
    doc.line(10,249,205 ,249);

    doc.text('Sub-Total' ,115 , 255);
    doc.text('USD$   '+datosin.subTotal ,200 , 255,{align: 'right', maxWidth: '40'});
    doc.text('ITBIS' ,115 , 260);
    doc.text('USD$   '+datosin.itbis ,200 , 260,{align: 'right', maxWidth: '40'});
    //doc.line(158,268,205 ,268);
    doc.text('Total a pagar' ,115 , 265);
    doc.text('USD$   '+datosin.total ,200 , 265,{align: 'right', maxWidth: '40'});
    doc.save('CE-'+ datosin.quoteNumber +'_'+fechaHoy.toLocaleString('es-ES').split(' ')[0]+'.pdf');

  }


}
