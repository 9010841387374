// helpers.ts
export function formatNumber(value: number): string {
    const options: Intl.NumberFormatOptions = {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true // Para usar separadores de miles
    };
    return new Intl.NumberFormat('es-ES', options).format(value);
}

export function generateHex(codigoQ: number): string {
    return codigoQ ? codigoQ.toString(16).toUpperCase() : '';
}

export function getDeliveryRange(deliveryTime: number): string {
    const start = deliveryTime;
    const end = deliveryTime + 1;
    return `${start} a ${end} Semanas.`;
}
