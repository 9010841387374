import { Component, OnInit } from '@angular/core';

import { HeaderSeoService } from '../../services/header-seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(
    private metaService: HeaderSeoService
    ) { }

  ngOnInit() {
    
    this.metaService.setTitle('Acerca de WWW.PARTES.DO')
    this.metaService.updateTag('description', 'WWW.PARTES.DO')
    this.metaService.updateTag('keywords', 'WWW.PARTES.DO')
  }

}