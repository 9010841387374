import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isVisible: boolean = false;
  loginError: string | null = null;

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService, 
    private router: Router,
    private loginService: LoginService,
    private toaster: Toaster
  ) {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {}

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  onSubmit() {
    if (this.loginForm.valid) {
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;

      this.loginService.login(email, password).subscribe(
        (response) => {
          if (response && response.token) {
            this.authService.login(response.user);
            this.toaster.open({ text: 'Inicio de sesión exitoso', type: 'success' });
            this.closeLogin();
            this.router.navigate(['/']);
          } else {
            this.toaster.open({ text: 'Usuario o contraseña incorrectos', type: 'danger' });
          }
        },
        (error) => {
          if (error.status === 401) {
            this.toaster.open({ text: 'Usuario o contraseña incorrectos', type: 'danger' });
          } else {
            this.toaster.open({ text: 'Se produjo un error al intentar iniciar sesión', type: 'danger' });
          }
          console.error('Error de autenticación:', error);
        }
      );
    } else {
      this.toaster.open({ text: 'Por favor, completa todos los campos correctamente.', type: 'warning' });
    }
  }


  openLogin() {
    this.isVisible = true;
  }

  closeLogin() {
    this.loginForm.reset();
    this.isVisible = false;
    this.loginError = null; 
  }
}
