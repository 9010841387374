import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'quoteform',
  templateUrl: './quoteform.component.html',
  styleUrls: ['./quoteform.component.scss']
})
export class QuoteformComponent implements OnInit {
  @Input() profileForm: FormGroup;
  @Output() formSubmitted = new EventEmitter<void>();

  ngOnInit() {
    const savedData = localStorage.getItem('quoteFormData');
    if (savedData) {
      const formData = JSON.parse(savedData);
      this.profileForm.patchValue({
        clientName: formData.clientName || '',
        clientEmail: formData.clientEmail || '',
        rncNumber: formData.rncNumber || '',
        isFreezone:formData.isFreezone || false,
        currency:formData.currency || 'USD'
      });
    }
  }

  onSubmit() {
    if (this.profileForm.valid) {
      this.formSubmitted.emit(); 
    }
  }
}
