import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(
    private router: Router
  ){  }

  ngOnInit(  ){
    //this.metaService.addTag({name: 'keywords', content: 'partes, partes electricas, tienda de partes, '})
  }

  onActivate(event) {
    window.scroll(0,0);
  }

}
