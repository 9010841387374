import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { LoginComponent } from '../login/login.component'; 
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  showQuotesLink: boolean = false;
  userEmail: string | null = null;
  private authSubscription!: Subscription;
  public cantidadItems = 0;

  @ViewChild(LoginComponent) loginComponent!: LoginComponent; 

  constructor(private router: Router, private authService: AuthService, private cartService:CartService) {}

  ngOnInit() {
    this.userEmail = localStorage.getItem('userEmail');
    this.showQuotesLink = !!this.userEmail;

    this.cartService.itemsObservable$.subscribe(cart => {
      this.cantidadItems = cart.reduce((acc, item) => acc + item.quantity, 0);
    });

    this.authSubscription = this.authService.loggedIn$.subscribe(isLoggedIn => {
      this.userEmail = localStorage.getItem('userEmail');
      this.showQuotesLink = isLoggedIn && !!this.userEmail;
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  showLogin() {
    if (this.loginComponent) {
      this.loginComponent.openLogin();
    }
  }

  logout() {
    this.authService.logout();
    this.userEmail = null;
    this.showQuotesLink = false;
    this.router.navigate(['/']);
  }
}
