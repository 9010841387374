import { Injectable } from '@angular/core';

import { Observable, of, Subject, BehaviorSubject } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Product } from '../models/product';
import { Quote } from '../models/quote';


@Injectable({
  providedIn: 'root'
})
export class CartService {


  public cart: Array<{ product: any; quantity: number }> = [];
  private cartKey = 'myCart';
  private itemsSubject = new BehaviorSubject<Array<{ product: any; quantity: number }>>(this.cart);
  public itemsObservable$ = this.itemsSubject.asObservable();
 
  URL_API = "https://www.partes.do/api/ises/quote";
  //URL_API = "http://localhost:3000/api/ises/quote";

  constructor(private httpBackend: HttpClient) { 
    this.loadCartFromLocalStorage();
  }

  getCart() {
    return this.cart;
  }
  getLength() {
    return this.cart.length;
  }

  add(product: any, quantity: number) {
    const existingProductIndex = this.cart.findIndex(item => item.product.code === product.code);

    if (existingProductIndex > -1) {
      this.cart[existingProductIndex].quantity += quantity;
    } else {
      this.cart.push({ product, quantity });
    }
    this.itemsSubject.next(this.cart);
    this.saveCartToLocalStorage();
  }

  exists(product: any): boolean {
    return this.cart.some(item => item.product.code === product.code);
  }

  del(indProduct) {
    this.cart.splice(indProduct, 1);
    this.itemsSubject.next(this.cart);
    this.saveCartToLocalStorage();
  }

  reset() {
    this.cart = [];
    this.itemsSubject.next(this.cart);
    this.saveCartToLocalStorage();
  }

  // Obtener carrito desde localStorage
  private loadCartFromLocalStorage() {
    const cartFromLocalStorage = localStorage.getItem(this.cartKey);
    if (cartFromLocalStorage) {
      this.cart = JSON.parse(cartFromLocalStorage);
      this.itemsSubject.next(this.cart); 
    }
  }

  private saveCartToLocalStorage() {
    localStorage.setItem(this.cartKey, JSON.stringify(this.cart));
  }

  send2Server(profile, valorTotal) {
    return this.httpBackend.post(this.URL_API,
      {
        status: 'Ok',
        data: {
          profile: profile,
          valorTotal: valorTotal,
          cart: this.cart
        }
      },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }
    );
  }



  getQuotes(term: any, page: number, limit: number): Observable<any> {
    const body = {
      from: term.from,
      to: term.to,
      searchTerm: term.searchTerm,
      page: page,
      limit: limit
    };  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpBackend.post<any>(`${this.URL_API}/list`, body, { headers })
      .pipe(
        catchError(this.handleError('getQuotes', []))
      );
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
