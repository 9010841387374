import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';
import { catchError } from 'rxjs/operators';
import { downloadPDF } from '../../utils/pdfUtils';
import { of } from 'rxjs';

@Component({
  selector: 'app-quoteList',
  templateUrl: './quoteList.component.html',
  styleUrls: ['./quoteList.component.scss']
})
export class QuoteListComponent implements OnInit {
  filterForm: FormGroup;
  quotes = [];
  filteredQuotes = [];
  currentPage = 1;
  itemsPerPage = 25;
  totalPages = 1;
  totalQuotes: number = 0;
  preloader = false;
  maxDate: string;


  constructor(private fb: FormBuilder, private quotesService: CartService) {
    const today = new Date();

    // Calcular la fecha de hace una semana
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);

    // Formatear la fecha de hoy y la de hace una semana a 'YYYY-MM-DD'
    const localToday = new Date(today.getTime() - today.getTimezoneOffset() * 60000);
    const localLastWeek = new Date(lastWeek.getTime() - lastWeek.getTimezoneOffset() * 60000);

    const formattedToday = localToday.toISOString().split('T')[0];
    const formattedLastWeek = localLastWeek.toISOString().split('T')[0];
    this.maxDate = formattedToday;

    this.filterForm = this.fb.group({
      from: [formattedLastWeek],
      to: [formattedToday],
      searchTerm: ['']
    });
  }

  ngOnInit(): void {
    this.filterQuotes();
  }

  filterQuotes() {
    this.currentPage = 1;
    this.loadQuotes();
  }

  loadQuotes() {
    this.preloader = true;
    const { from, to, searchTerm } = this.filterForm.value;
    const page = this.currentPage;
    const limit = this.itemsPerPage;


    const term = {
      from: from || null,
      to: to || null,
      searchTerm: searchTerm || ''
    };

    // Llama al servicio para obtener las cotizaciones filtradas
    this.quotesService.getQuotes(term, page, limit).pipe(
      catchError(error => {
        console.error('Error al obtener las cotizaciones:', error);
        return of({ data: [], total: 0 });
      })
    ).subscribe(response => {
      this.filteredQuotes = response.quotes.map(quote => ({
        ...quote,
        quoteNumber: `CE-${this.generateHex(quote.quoteNumber)}`
      }));
      this.totalQuotes = response.totalQuotes;
      this.totalPages = Math.ceil(this.totalQuotes / this.itemsPerPage);
      this.preloader = false;
    });
  }

  changePage(page: number) {
    this.currentPage = page;
    this.loadQuotes();
  }

  get paginatedQuotes() {
    return this.filteredQuotes;
  }

  generateHex(codigoQ: number): string {
    return codigoQ ? codigoQ.toString(16).toUpperCase() : '';
  }


  async downLoandPDF(data) {
    const { clientEmail, clientName, currency, isFreeZone, note, rncNumber } = data;

    const client = {
      clientEmail,
      clientName,
      currency,
      isFreeZone,
      note,
      rncNumber
    };

    if (Array.isArray(data.products)) {
      const cart = data.products.map(product => ({
        product: {
          _id: product._id,
          code: product.code,
          price: product.price,
          description: product.description,
          stock: product.stock,
          picture: product.picture,
          deliveryTime: product.deliveryTime,
          imageUrl: product.imageUrl
        },
        quantity: product.quantity.toString()
      }));
      await downloadPDF(data, cart, data.products.length, client, data.dateQuote);
    } else {
      console.error('data.products no es un array.');
    }

  }

  getDisplayedRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(this.currentPage * this.itemsPerPage, this.totalQuotes);
    return `${start}-${end}`;
  }


}
