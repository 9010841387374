import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CartService } from '../../services/cart.service'
import { Subscription } from 'rxjs';
import { Toaster } from 'ngx-toast-notifications';
import { getImageUrl } from '../../utils/imageUrlExt';
import { Router } from '@angular/router';
import { downloadPDF } from '../../utils/pdfUtils';



declare var $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @ViewChild('formModal') formModal: ElementRef;
  cart: any;
  datos: any;
  profile: any;
  valorTotal: number;
  activeDownload: boolean;
  public cantidadItems: number = 0;
  exchangeRate = 50.68;

  profileForm = new FormGroup({
    clientName: new FormControl('', [Validators.required, Validators.minLength(4)]),
    clientEmail: new FormControl('',[this.emailValidator()]),
    rncNumber: new FormControl(''),
    isFreezone: new FormControl(false),
    note: new FormControl(''),
    currency: new FormControl('USD')
  });

  preloader: boolean = false;
  get clientName() { return this.profileForm.get('clientName'); }

  constructor(
    private toaster: Toaster,
    private cartService: CartService,
    private router: Router) { }

    ngOnInit(): void {
      this.cartService.itemsObservable$.subscribe(cart => {
        this.cart = cart;
        this.cantidadItems = cart.reduce((acc, item) => acc + item.quantity, 0);
        this.calcularTotal();
      });
    }
  

  emailValidator() {
    return (control: any) => {
      const email = control.value;
      if (!email) {
        return null; 
      }
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email) ? null : { emailInvalid: true };
    };
  }

  gotoHome() {
    this.router.navigate(['/']);
  }

  del(ind) {
    this.cartService.del(ind);
    this.cart = this.cartService.getCart();
    this.cantidadItems = this.cartService.getLength();
    this.toaster.open(
      {
        text: 'Se ha eliminado un artículo del carrito',
        caption: 'Hecho',
        duration: 1000,
        type: 'success'
      });
    if (this.cantidadItems > 0) {
      this.calcularTotal();
    } else {
      this.valorTotal = 0;
      this.gotoHome();
    }
  }

  calcularTotal() {
    this.valorTotal = 0;
    this.cantidadItems = this.cartService.getLength();
    if (this.cantidadItems > 0) {
      for (let entry of this.cartService.cart) {
        let quantity = entry['quantity'];
        let price = entry['product']['price'];
        let total = quantity * price;
        this.valorTotal += total;
      }
    } else {
      this.valorTotal = 0;
    }
  }

  resetCart(): void {
    this.cartService.reset();
    this.cart = this.cartService.getCart();
    this.gotoHome();
  }

  getProfile(data) {
    this.profile = data;


  }

  openModal() {
    if(this.valorTotal<500){
      this.toaster.open(
        {
          text: 'La cotización debe ser mayor o igual a USD $10',
          caption: 'Error',
          duration: 2000,
          type: 'danger'
        });
        return true;
    }else{
      const modalElement = this.formModal.nativeElement;
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      document.body.classList.add('modal-open');
    }

  }

  closeModal() {
    this.preloader = false;
      this.profileForm.reset({
    clientName: '',
    clientEmail: '',
    rncNumber: '',
    isFreezone: false,
    note: '',
    currency: 'USD'
  });
    const modalElement = this.formModal.nativeElement;
    modalElement.classList.remove('show');
    modalElement.style.display = 'none';
    document.body.classList.remove('modal-open');
  }

  onConfirm() {
    if (this.profileForm.valid) {
      this.preloader = true;
      this.profile = this.profileForm.value;
      const formData = {
        clientName: this.profileForm.get('clientName').value,
        clientEmail: this.profileForm.get('clientEmail').value,
        rncNumber: this.profileForm.get('rncNumber').value,
        isFreezone:this.profileForm.get('isFreezone').value,
        currency:this.profileForm.get('currency').value
      };
      localStorage.setItem('quoteFormData', JSON.stringify(formData));
      this.submitValues(); 
    } else {
      this.preloader = false;
      this.markAllControlsAsTouched(this.profileForm); 
    }
  }

  markAllControlsAsTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control); 
      } else {
        control.markAsTouched(); 
      }
    });
  }

  onFormSubmitted() {
    this.onConfirm(); 
  }

  submitValues() {  
    this.cartService.send2Server(this.profile,
      this.valorTotal).subscribe(       
        async (data) => {      
          if (typeof (data) !== 'undefined') {
            await downloadPDF(data, this.cart, this.cantidadItems, this.profile,null);
            await this.resetCart();
            await $('.modal').modal('hide');
            await this.gotoHome();
            this.toaster.open(
              {
                text: 'Se ha generado una cotización exitosamente!',
                caption: 'Hecho',
                duration: 1000,
                type: 'success'
              });
          }
          this.activeDownload = true;
          this.closeModal();
          return true;

        },
        error => {
          this.toaster.open(
            {
              text: 'Se ha generado un error al registrar la cotización',
              caption: 'Error',
              duration: 1000,
              type: 'danger'
            });
          this.closeModal();
        }
      );
      }

      formatNumber(value: number): string {
        const options: Intl.NumberFormatOptions = {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true // Para usar separadores de miles
        };
        return new Intl.NumberFormat('es-ES', options).format(value);
    }

  generateHex(codigoQ: number): string {
    return codigoQ ? codigoQ.toString(16).toUpperCase() : '';
  }

  getImageUrl(imageName): string {
    return getImageUrl(imageName);
  }

  getDeliveryRange(deliveryTime: number): string {
    const start = deliveryTime;
    const end = deliveryTime + 1;
    return `${start} a ${end} Semanas.`;
  }

}


