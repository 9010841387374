import { Component, OnInit } from '@angular/core';
import { Product } from '../../models/product';
import { SearchesService } from '../../services/searches.service';
import { CartService } from '../../services/cart.service';
import { Toaster } from 'ngx-toast-notifications';
import { HistorialService } from 'src/app/services/historial.service';
import { HeaderSeoService } from 'src/app/services/header-seo.service';
import { ChangeDetectorRef } from '@angular/core';
import { getImageUrl } from 'src/app/utils/imageUrlExt';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  products: Product[];
  searchValue = '';
  preloader: boolean;
  totalPages: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 50;
  totalProducts: number = 0;
  defaultImageUrl = 'assets/img/notImage.png';
  selectedProduct: any;
  visiblePages: (number | string)[] = [];// Array para almacenar las páginas visibles
  maxVisiblePages: number = 3; // Número máximo de botones de página visibles


  constructor(
    private toaster: Toaster,
    private searchService: SearchesService,
    private historialService: HistorialService,
    private cartService: CartService,
    private headerSeoService: HeaderSeoService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.preloader = false;
    this.selectedProduct = this.sharedService.getSelectedProduct();
    if (this.selectedProduct) {
      this.searchValue = `${this.selectedProduct.imageCode} ${this.selectedProduct.product}`;
      this.filterProducts();
      this.sharedService.clearSelectedProduct();
    }
    this.headerSeoService.setTitle('WWW.PARTES.DO');
    this.headerSeoService.updateTag('description', `WWW.PARTES.DO Es una herramienta de trabajo propiedad de
      ISES Industrial Parts & Services SRL,
      la cual ha sido diseñada para simplificar los procesos de compra para la adquisición de materiales de nuestros clientes.`);
    this.headerSeoService.updateTag('keywords', ' Partes, Partes eléctricas, equipos eléctricos, equipos industriales, compra, venta');
  }

  filterProducts() {
    this.currentPage = 1;
    this.getProducts();
  }

  getProducts(): void {
    this.preloader = true;

    if (!(this.searchValue.length < 3)) {
      this.searchService.getProducts(this.searchValue, this.currentPage, this.itemsPerPage).subscribe(products => {
        this.products = products.products;
        this.totalPages = products.totalPages;
        this.totalProducts = products.totalProducts;
        this.preloader = false;
        this.historialService.add(this.searchValue);
        this.preloader = false;
        this.cdr.detectChanges();

        if (this.selectedProduct) {
          this.products.sort((a, b) => {
            if (a.code === this.selectedProduct.imageCode) {
              return -1; 
            } else if (b.code === this.selectedProduct.imageCode) {
              return 1; 
            } else {
              return 0; 
            }
          });
        }
        this.updateVisiblePages();

        if (this.products == null) {
          this.toaster.open({
            text: 'No se han encontrado resultados para esta búsqueda',
            caption: 'Lo sentimos',
            duration: 1000,
            type: 'warning'
          });
        }
      }, error => {
        this.preloader = false;
        this.toaster.open({
          text: 'Error al cargar productos',
          caption: 'Error',
          duration: 1000,
          type: 'danger'
        });
      });
    } else {
      this.toaster.open({ text: 'Debe Ingresar al menos 3 letras', caption: 'Error', duration: 1000, type: 'danger' });
      this.preloader = false;
    }
  }

  add2Cart(product: any, quantity: number) {
    if (quantity >= 0) {
      if (!this.cartService.exists(product)) {
        this.cartService.add(product, quantity);
        this.toaster.open({
          text: 'Artículo agregado al carrito',
          caption: 'Hecho!',
          duration: 1000,
          type: 'success'
        });
      } else {
        this.toaster.open({
          text: 'Este artículo ya fue agregado',
          caption: 'Disculpe!',
          duration: 1000,
          type: 'warning'
        });
      }
    } else {
      this.toaster.open({
        text: 'Cantidad negativa!',
        caption: 'Error!',
        duration: 1000,
        type: 'danger'
      });
    }
  }

  clearProducts(){
    this.searchValue = '';
    this.products=[];
    this.totalPages = 0;
    this.totalProducts = 0;
    this.historialService.add(this.searchValue);
    this.cdr.detectChanges();
  }

  reset() {
    this.searchValue = '';
  }

  PriceSale(price: number) {
    return (price * 1.151234) / 50.68;
  }

  onImageError(event: Event) {
    (event.target as HTMLImageElement).src = this.defaultImageUrl;
  }

  handleImageError(event: any) {
    if (event.target.src !== this.defaultImageUrl) {
      event.target.src = this.defaultImageUrl;
    }
  }

  changePage(page: number): void {
    this.currentPage = page;    
    this.getProducts();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getDisplayedRange(): string {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(this.currentPage * this.itemsPerPage, this.totalProducts);
    return `${start}-${end}`;
  }

  updateVisiblePages(): void {
    const halfVisible = Math.floor(this.maxVisiblePages / 2);
    let startPage = Math.max(1, this.currentPage - halfVisible);
    let endPage = Math.min(this.totalPages, this.currentPage + halfVisible);
  
    if (endPage - startPage + 1 < this.maxVisiblePages) {
      if (startPage === 1) {
        endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1);
      } else if (endPage === this.totalPages) {
        startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
      }
    }
  
    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  
    // Reset array and add "..." where necessary
    const updatedVisiblePages: (number | string)[] = [];
  
    // Add leading "..." if there are pages before the first visible page
    if (startPage > 1) {
      updatedVisiblePages.push(1);
      if (startPage > 2) {
        updatedVisiblePages.push('...');
      }
    }
  
    // Add visible pages
    updatedVisiblePages.push(...this.visiblePages);
  
    // Add trailing "..." if there are pages after the last visible page
    if (endPage < this.totalPages) {
      if (endPage < this.totalPages - 1) {
        updatedVisiblePages.push('...');
      }
      updatedVisiblePages.push(this.totalPages);
    }
  
    this.visiblePages = updatedVisiblePages;
  }
  
  
  

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }
  

}
