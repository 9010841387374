import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Importe funciones auxiliares necesarias
import { formatNumber, generateHex, getDeliveryRange } from '../utils/helpers';

export function downloadPDF(datosin: any, cart: any[], cantidadItems: number, profile: any, quoteDate: any): void {
    let exchangeRate = 52;
    datosin.isFreeZone = profile.isFreezone ? 'Si' : 'No';
    datosin.clientEmail = datosin.clientEmail || '-';
    datosin.rncNumber = datosin.rncNumber || '-';
    const currency = datosin.currency;

    // Crear documento PDF
    const doc = new jsPDF({ format: 'letter' });
    const datos: any[] = [];

    // Procesar los datos del carrito
    if (cantidadItems) {
        let i = 1;
        for (const entry of cart) {
            const quantity = parseInt(entry['quantity']);
            let price = parseFloat(entry['product']['price']);
            if (currency === 'RD') {
                price *= exchangeRate;
            }
            const total = quantity * price;
            const deliveryTime = parseInt(entry['product']['deliveryTime']);
            const deliveryRange = getDeliveryRange(deliveryTime);

            datos.push([
                i++,
                quantity,
               /* entry['product']['code'],*/
                entry['product']['description'],
                formatNumber(price),
                formatNumber(total),
            ]);

            datos.push([
                '',  // Vacío en la columna "Item"
                '',  // Vacío en la columna "Cant."
               /* '',  // Vacío en la columna "Código"*/
                { content: `Tiempo de Entrega: ${deliveryRange}`, styles: { halign: 'left' } }, // Ocupa 4 columnas
                '',  // Vacío en la columna "SubTotal"
            ]);
        }
    }
    // Añadir imagen y texto al PDF
    const fechaHoy = quoteDate ? new Date(quoteDate) : new Date();
    const imgLogo = new Image();
    imgLogo.src = 'assets/img/logo.png';
    const codigoHexa = generateHex(datosin.quoteNumber);

    doc.addImage(imgLogo, 'PNG', 15, 15, 65, 30);
    doc.setFillColor('#1565C0');
    doc.setTextColor('#000');
    doc.setFontSize(10);

    doc.text('Contacto: ', 15, 50);
    doc.text('(809)955-5777', 36, 50);
    doc.text('Email: ', 15, 55);
    doc.text('ventas@isesindustrial.com / ises1114@hotmail.com', 36, 55);
    doc.text('RNC: ', 15, 60);
    doc.text('1-31-48955-9', 36, 60);
    doc.text('Dirección: ', 15, 65);
    doc.text('Km. 4 1/2 Autopista 6 de noviembre, Calle Santiago', 36, 65);
    doc.text('Suero. San Cristóbal', 36, 70);
    /*doc.text('Sitio Web: ', 15, 75);
    doc.text('www.partes.do', 36, 75);*/

    doc.text('Cotización #: ', 128, 20);
    const codeQuote = quoteDate ? codigoHexa : 'CE-' + codigoHexa;
    doc.text(codeQuote, 160, 20);

    doc.text('Solicitado por: ', 128, 35);
    doc.text(datosin.clientName, 160, 35);
    doc.text('Fecha: ', 128, 40);
    doc.text(fechaHoy.toLocaleDateString('es-ES'), 160, 40);

    doc.text('Email: ', 128, 45);
    doc.text(datosin.clientEmail, 160, 45);
    doc.text('RNC: ', 128, 50);
    doc.text(datosin.rncNumber, 160, 50);
   /* doc.text('Zona franca: ', 128, 55);
    doc.text(datosin.isFreeZone, 160, 55);*/

    doc.setFontSize(16);
    doc.text('COTIZACIÓN ELECTRÓNICA', 70, 85);

    // Tabla de datos
    doc.autoTable({
        head: [['Item', 'Cant.',/* 'Código',*/ 'Descripción', 'Precio', 'SubTotal']],
        body: datos,
        columnStyles: {
            4: { halign: 'right' },
            5: { halign: 'right' }
        },
        didDrawPage: (data) => {
            doc.setFontSize(10);
            doc.text(`Página ${data.pageNumber} de ${data.pageCount}`, 200, 15, { align: 'right' });
        },
        margin: { top: 90, bottom: 30 },
        pageBreak: 'auto',
    });

    // Añadir notas y subtotales
    const pageHeight = doc.internal.pageSize.height;
    doc.setFontSize(6);
    doc.text('NOTA 1: Cotización sujeta a términos de la página.', 15, pageHeight - 40);
    doc.text('NOTA 2: Cotización Válida por dos (2) semanas.', 15, pageHeight - 35);
    doc.text(`NOTA 3: Cotización en ${currency}.`, 15, pageHeight - 30);

    doc.setTextColor('#000');
    doc.setFontSize(12);
    doc.setFillColor('#000');
    doc.line(10, pageHeight - 25, 205, pageHeight - 25);
    doc.line(10, pageHeight - 24, 205, pageHeight - 24);

    // Subtotales y totales
    doc.text('Sub-Total:', 115, pageHeight - 15);
    doc.text(`${currency} ${formatNumber(parseFloat(datosin.subTotal))}`, 200, pageHeight - 15, { align: 'right' });
    doc.text('ITBIS:', 115, pageHeight - 10);
    doc.text(`${currency} ${formatNumber(parseFloat(datosin.itbis))}`, 200, pageHeight - 10, { align: 'right' });
    doc.text('Total a pagar:', 115, pageHeight - 5);
    doc.text(`${currency} ${formatNumber(parseFloat(datosin.total))}`, 200, pageHeight - 5, { align: 'right' });

    // Guardar el PDF
    doc.save(`Cotización ${codigoHexa}.pdf`);

}
