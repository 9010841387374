import { Component, OnInit } from '@angular/core';
import { HeaderSeoService } from 'src/app/services/header-seo.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  constructor(
    private headerSeoService: HeaderSeoService 
    ) { }

  ngOnInit() {
    this.headerSeoService.setTitle('Facturación')
    this.headerSeoService.updateTag('description','¿Cómo Puedo facturar los productos de www.partes.do ?')
    this.headerSeoService.updateTag('keywords','www.partes.do, partes, facturacion en partes.do')
  }

}
