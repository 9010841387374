import { Injectable } from '@angular/core';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class HistorialService {

  listaHistorialConsultas: String[] = [];
  listaHistorialProducts: Product[] = [];
  private valorMaximo = 5;

  constructor() {
    this.listaHistorialConsultas = JSON.parse(localStorage.getItem('listaHistorialConsultas'));
    if(this.listaHistorialConsultas === null){
      this.listaHistorialConsultas = [];
    }
    //this.listaHistorialProducts = JSON.parse(localStorage.getItem('listaHistorialProducts'));
   }

  getConsultas(){
    return this.listaHistorialConsultas;
  }
  getProductos(){
    return this.listaHistorialProducts;
  }

  //add(consulta: String, productoNuevo: Product){
  add(consulta: String){
    if(consulta != undefined && consulta != '' ){

      if(this.listaHistorialConsultas.length < this.valorMaximo){
        this.listaHistorialConsultas.unshift(consulta);
        //this.listaHistorialProducts.unshift(productoNuevo);
      }else{
        this.listaHistorialConsultas.pop();
        //this.listaHistorialProducts.pop();
        this.listaHistorialConsultas.unshift(consulta);
        //this.listaHistorialProducts.unshift(productoNuevo);
      }
      localStorage.setItem('listaHistorialConsultas', JSON.stringify(this.listaHistorialConsultas));
      //localStorage.setItem('listaHistorialProducts', JSON.stringify(this.listaHistorialProducts));
    }
  }
  del(position: number){
    this.listaHistorialConsultas.splice( position, 1);
    //this.listaHistorialProducts.splice( position, 1);
    localStorage.setItem('listaHistorialConsultas', JSON.stringify(this.listaHistorialConsultas));
    //localStorage.setItem('listaHistorialProducts', JSON.stringify(this.listaHistorialProducts));
    
  }

  
  delAll(position: number){
    this.listaHistorialConsultas = [];
    //.listaHistorialProducts = [];
    localStorage.setItem('listaHistorialConsultas', JSON.stringify(this.listaHistorialConsultas));
    //.setItem('listaHistorialProducts', JSON.stringify(this.listaHistorialProducts));
    

  }


}
