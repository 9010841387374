import { Injectable } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HeaderSeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  setTitle(title: string){
    this.titleService.setTitle(title);
  }
  addTag(name, content){
    this.metaService.addTag({name, content});
  }
  updateTag(name, content){
    this.metaService.updateTag({name, content});
  }
  removeTag(name: string){
    this.metaService.removeTag(name);
  }
}
