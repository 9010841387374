import { Component, OnInit } from '@angular/core';
import { HeaderSeoService } from 'src/app/services/header-seo.service';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.scss']
})
export class TerminosComponent implements OnInit {

  constructor(
    private headerSeoService: HeaderSeoService
  ) { }

  ngOnInit() {
    this.headerSeoService.setTitle('Términos y Condiciones : WWW.PARTES.DO')
    this.headerSeoService.updateTag('description','Terminos y condicines de www.partes.do')
    this.headerSeoService.updateTag('keywords','terminos y condiciones')
  }

}
