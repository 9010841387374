import { Injectable  } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

 URL_API = "https://www.partes.do/api/ises/auth";
 // URL_API = "http://localhost:3000/api/ises/auth";


  constructor(private httpBackend :HttpClient) {
  }

  
  login(email,password): Observable<any> {
    const requestBody = {
      email: email,
      password: password
    };

    let users = this.httpBackend.post<any>(this.URL_API,
      {
        status: 'Ok',
        data: requestBody
      }).
    pipe(
      catchError(this.handleError('login', []))
    )
    ;
    return users;
    
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
