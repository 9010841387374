import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { CartService } from '../../services/cart.service'
import { Product } from '../../models/product'
@Component({
  selector: 'productcart',
  templateUrl: './productcart.component.html',
  styleUrls: ['./productcart.component.scss']
})
export class ProductcartComponent implements OnInit, OnDestroy {

  public cantidad: number = 0;
  public subscription: Subscription;

  constructor(private cartService: CartService,
    private router: RouterModule) { }

    ngOnInit() {
      this.subscription = this.cartService.itemsObservable$.subscribe((items: Array<any>) => {
        this.cantidad = items.length;
      });

    }
    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }
