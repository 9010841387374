import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }
  URL_API = 'http://www.partes.do/api/ises/contacts';
  //URL_API = 'http://localhost:3000/api/ises/contacts';

  SendEmail(data) {
    return this.http.post(`${this.URL_API}`, data);
  }
}
