import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { CartComponent } from './components/cart/cart.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { AboutComponent } from './components/about/about.component';
import { BillingComponent } from './components/billing/billing.component';
import { DatacollectorComponent } from './components/datacollector/datacollector.component';
import { QuoteListComponent } from './components/quoteList/quoteList.component';
import { SlideListComponent } from './components/slideList/slideList.component';
import { AuthGuard } from './auth.guard';
//import { ProductdetailComponent } from './components/productdetail/productdetail.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent},
  { path: 'cart', component: CartComponent },
  { path: 'billing', component: BillingComponent },
  { path: 'terms', component: TerminosComponent },
  { path: 'data-colector', component: DatacollectorComponent },
  { path: 'quoteList', component: QuoteListComponent, canActivate: [AuthGuard]},
  { path: 'slideList', component: SlideListComponent, canActivate: [AuthGuard]},
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
