import { Injectable  } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Product } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class SearchesService {

 URL_API = "https://www.partes.do/api/ises/main";
  //URL_API = "http://localhost:3000/api/ises/main";

  searchValue: string;

  constructor(private httpBackend :HttpClient) {
  }

  reset(){
    this.searchValue = '';
  }

  getProducts(term,page,limit): Observable<any> {
    const requestBody = {
      data: term,
      page: page,
      limit: limit
    };

    let products = this.httpBackend.post<any>(this.URL_API,
      {
        status: 'Ok',
        data: requestBody
      }).
    pipe(
      catchError(this.handleError('getProducts', []))
    )
    ;
    return products;
    
  }

  SendEmail(data) {
    return this.httpBackend.post(`${this.URL_API}`, data);
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
