import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import { HeaderSeoService } from 'src/app/services/header-seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
contactForm: FormGroup;
  constructor(private fb: FormBuilder, private service: ContactService,
    private headerSeoService: HeaderSeoService
    ) {
    this.contactForm = this.fb.group({
      company : ['', Validators.required ],
      name : ['', Validators.required ],
      email : ['', Validators.required ],
      text : ['', Validators.required ]
    });
  }

  ngOnInit() {
    this.headerSeoService.setTitle('Contacto : WWW.PARTES.DO');
    this.headerSeoService.updateTag('description', 'Contacto WWW.PARTES.DO');
    this.headerSeoService.setTitle('keywords : Contacto ,WWW.PARTES.DO');
  }
sendSms() {
  const data = {
from: this.contactForm.value.email,
subject: 'Un cliente quiere contactarnos',
text: `${this.contactForm.value.name}
de la Compañía: ${this.contactForm.value.company}
quiere comunicarse con Partes, dejó el siguiente Comentario
${this.contactForm.value.text}
su correo es: ${this.contactForm.value.email}`
  };
  this.service.SendEmail(data).subscribe(
    result => console.log(result),
    err => console.log(err)
  );
}
}
