import { Component, OnInit,ViewEncapsulation  } from '@angular/core';
import { getImageUrl } from '../../utils/imageUrlExt';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { SlideService } from 'src/app/services/slide.service';
import { Toaster } from 'ngx-toast-notifications'; 
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-slideshome',
  templateUrl: './slideshome.component.html',
  styleUrls: ['./slideshome.component.scss']
})
export class SlideshomeComponent implements OnInit {  
  currentPage = 1;
  itemsPerPage = 10000;
  totalPages = 1;
  totalSlides: number = 0;
  filteredSlides:[];
  searchTerm='';
  slides= [];
 

  slideConfig = {
    "slidesToShow": 4, 
    "slidesToScroll": 4,
    "infinite": true,
    "autoplaySpeed": 4000,
    "autoplay": true,
    "cssEase": 'linear',
    "arrows": true, 
    "prevArrow": '<button type="button" class="slick-prev"></button>',
    "nextArrow": '<button type="button" class="slick-next"></button>',
    "responsive": [
      {
        "breakpoint": 1024,
        "settings": {
          "slidesToShow": 3,
          "slidesToScroll": 3,
          "infinite": true,
          "autoplaySpeed": 4000,
          "arrows": true  
        }
      },
      {
        "breakpoint": 720,
        "settings": {
          "slidesToShow": 2,
          "slidesToScroll": 2,
          "infinite": true,
          "autoplaySpeed": 3000,
          "arrows": true  
        }
      },
      {
        "breakpoint": 480,
        "settings": {
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "infinite": true,
          "autoplaySpeed": 2000,
          "arrows": true  
        }
      }
    ]
};

constructor(private router: Router, 
            private sharedService: SharedService, 
            private slideService: SlideService,
            private toaster: Toaster) {}

  ngOnInit() {
    this.loadSlideImage();
    this.slides = this.slides.map(slide => ({
      ...slide,
      img: getImageUrl(slide.img)
    }));
  }

  loadSlideImage(){
    const page = this.currentPage;
    const limit = this.itemsPerPage;
    const term = {
      searchTerm: this.searchTerm || ''
    };
    this.slideService.getSlides(term, page, limit).pipe(
      catchError(error => {
        this.toaster.open({ text: 'Error al obtener las imágenes. Por favor, intente nuevamente.', type: 'danger' });
        return of({ data: [], totalSlides: 0 });
      })
    ).subscribe(response => {
      this.filteredSlides = response.slides;
    });
  }

  
  selectProduct(imageCode: any, product: any) {
    this.sharedService.setSelectedProduct(imageCode, product);
    this.router.navigate(['/temp']).then(() => {
      this.router.navigate(['/']);
    });
}
}
