export function getImageUrl(imageName: string): string {
    const extensions = ['.png', '.jpg', '.jpeg'];
    for (let ext of extensions) {
      const url = `https://vcm.com.do/img/items/${imageName}${ext}`;
      return url; 
    }
    return 'https://vcm.com.do/img/items/default.png';
  }

 /* export async function getImageUrl(imageName: string): Promise<string> {
    const extensions = ['.png', '.jpg', '.jpeg'];
    
    for (let ext of extensions) {
      const url = `https://vcm.com.do/img/items/${imageName}${ext}`;
      
      try {
        // Realiza una solicitud HEAD para verificar si la imagen existe
        const response = await fetch(url, { method: 'HEAD' });
        
        if (response.ok) {
          return url; // Si la imagen existe, devuelve la URL
        }
      } catch (error) {
        console.error(`Error al verificar la imagen: ${url}`, error);
        // Si hay un error de red o similar, continúa con la siguiente extensión
      }
    }
  
    // Si ninguna imagen fue encontrada, devuelve la URL por defecto
    return 'https://ccm.com/img/items/default.png';
  }*/
  