import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();

  constructor() {
    this.checkInitialLoginStatus();
  }

  private checkInitialLoginStatus() {
    const userEmail = localStorage.getItem('userEmail');
    this.loggedIn.next(!!userEmail);
  }

  login(email: string) {
    localStorage.setItem('userEmail', email);
    this.loggedIn.next(true);
  }

  logout() {
    localStorage.removeItem('userEmail');
    this.loggedIn.next(false);
  }
}
